<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="text-center">
        <h1>
          Ontwikkelen
        </h1>
        <h3>Begint bij inzicht!</h3>
        <v-divider></v-divider>
      </v-col>


      <v-col cols="12">
        Hier vind je de werkbladen die je kunt gebruiken om inzicht te krijgen in jouw autonome reacties.
      </v-col>

      <v-col cols="12" class="mt-4">
        <p class="font-weight-regular">
          Je kunt ze downloaden, printen en invullen voor jouw eigen persoonlijke inzicht en ontwikkeling.
        </p>

      </v-col>

      <v-col cols="12" class="text-center">
        <h1>
          Documenten
        </h1>
        <v-divider></v-divider>
      </v-col>

      <v-col cols="12" class="text-center py-0">
        Hieronder zie je alle documenten die voor je klaar staan. Druk op de naam om te downloaden.
        <v-row justify="center" align="center">
          <v-col cols="12" sm="6" md="4" @click="downloadDocument1()" class="cursor-pointer pt-10">
            <v-icon style="font-size: 40px" class="mb-4">fa fa-file-alt</v-icon>
            <br>
            Kennismaken met jouw autonome zenuwstelsel<br>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: "dashboardHome",
  data: () => ({
    loading: false,
  }),
  methods: {
    downloadDocument1(){
      this.downloadPdfLoading = true;

      window.open(process.env.VUE_APP_BASE_URL + "kennismaken_met_je_autonome_zenuwstelsel.pdf", "_blank");

      setTimeout(this.stopLoadingDriverPdf, 2000);
    }
  }
};
</script>
